export const CapabilityStatuses = {
  WARNING: 'WARNING',
  HEALTHY: 'HEALTHY',
  NOT_ACTIVE: 'NOT_ACTIVE',
  CRITICAL: 'CRITICAL',
};

export const CapabilityStatusI8Map = {
  WARNING: 'common__warning',
  NOT_ACTIVE: 'common__inactive',
  CRITICAL: 'common__critical',
  HEALTHY: 'common__healthy',
};

export const CapabilityNames = {
  MONITORING: 'monitoring',
  FIREWALL: 'firewall',
  SSL: 'ssl',
  BACKUPS: 'backups',
};

export const CapabilityNamesI8Map = {
  MONITORING: 'common__monitoring',
  FIREWALL: 'mysites__firewall_cdn',
  SSL: 'common__ssl',
  BACKUPS: 'common__backups',
};

export const HelpMessageI8Map = {
  ScanFailed: 'mysites__security_check_failed',
  Blacklisted: 'mysites__site_is_blacklisted',
  Infected: 'mysites__site_has_malware',
  Outdated: 'mysites__site_has_outdated_software',
  Clean: 'mysites__site_is_clean',
  Paused: 'mysites__backups_paused',
  Disabled: 'common__disabled',
  Completed: 'mysites__backups_completed',
  BackupError: 'mysites__backups_error',
  NotProvisioned: 'mysites__not_provisioned',
  Failed: 'common__failed',
  InProgress: 'mysites__in_progress',
  NotLive: 'mysites__not_live',
  Live: 'common__ok',
  Upgrading: 'mysites__upgrading',
  Active: 'common__active',
  Inactive: 'common__inactive',
  UnreadMessages: 'mysites__unread_messages',
  NotEntitled: 'mysites__not_entitled',
  UNKNOWN: 'mysites__site_has_a_warning',
};

export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
};

/**
 * Get the short form of the capability status.
 * @param {Object} capability - Capability object
 * @param {Boolean} entitled - If the user is entitled to the capability
 * @returns {String} The short form capability status
 */
export const getShortCapabilityStatus = (capability, entitled) => {
  if (!entitled) return CapabilityStatuses.NOT_ACTIVE;

  switch (capability.status) {
    case 'ScanFailed':
    case 'Blacklisted':
    case 'Infected':
    case 'Outdated':
    case 'BackupError':
    case 'Failed':
    case 'Error':
      return CapabilityStatuses.CRITICAL;

    case 'Paused':
    case 'Disabled':
    case 'InProgress':
    case 'NotLive':
    case 'Upgrading':
      return CapabilityStatuses.WARNING;

    case 'NotProvisioned':
    case 'Inactive':
      return CapabilityStatuses.NOT_ACTIVE;

    default:
      return CapabilityStatuses.HEALTHY;
  }
};

/**
 * Get the capability help message id based on the capability status.
 * @param {Object} capability - Capability object
 * @param {String} name - Capability name
 * @param {Boolean} entitled - If the user is entitled to the capability
 * @returns {String} The capability help message id
 */
export const getCapabilityHelpMessageId = ({ capability, name, entitled }) => {
  if (capability.status === 'Error') {
    return `mysites__error_${name}`;
  }

  if (!entitled) {
    return HelpMessageI8Map.NotEntitled;
  }

  if (!capability.provisioned) {
    return HelpMessageI8Map.Inactive;
  }

  return HelpMessageI8Map[capability.status] || 'mysites__all_looks_good';
};

/**
 * Get the site class names based on the status of its capabilities.
 * @param {Object} site The site data
 * @returns {String} The site class names
 */
export const getSiteClassNames = (site) => {
  if (!site.subscriptions?.length) {
    return 'is-insecure';
  }

  if (
    getShortCapabilityStatus(site.monitoring, site.entitlements?.monitoring) ===
    CapabilityStatuses.CRITICAL
  ) {
    return 'has-critical';
  }

  return '';
};

/**
 * Site search suggestions.
 * @param {Array} sites Array of Site objects retrieved from the BFF
 * @param {String} search Search domain string
 * @returns {String | null} Returns the search suggestion or null if no suggestion is found
 */
export const createSearchSuggestions = (sites, search) => {
  // Site Search suggestions.
  let siteSuggestion = null;
  if (search) {
    /* Find a suggestion, case-insensitive starts with current value */
    siteSuggestion = sites.find((opt) =>
      new RegExp(`^${search}`, 'gi').test(opt),
    );
    // /* If suggestion is found, use the letters typed and replace the case */
    siteSuggestion =
      siteSuggestion &&
      siteSuggestion.replace(/./gi, (ch, i) => search.charAt(i) || ch);

    return siteSuggestion ?? null;
  }

  return null;
};

/**
 * Sort the sites by the scope and order.
 *
 * @param {Array} sitesList Array of user sites
 * @param {String} scope The scope to sort by
 * @param {String} scopeSort The order to sort by
 *
 *  @returns {Array} The sorted sites list
 */
export const sortSitesByScope = (sitesList, scope, scopeSort) => {
  const favourites = sitesList.filter((site) => site.isFavourite);
  const nonFavourites = sitesList.filter((site) => !site.isFavourite);

  // Sort favourites
  const sortedFavourites = [...favourites].sort((a, b) => {
    if (scope === 'domain') {
      return scopeSort === 'asc'
        ? a.domain.localeCompare(b.domain)
        : b.domain.localeCompare(a.domain);
    }
    return scopeSort === 'asc'
      ? a[scope].status.localeCompare(b[scope].status)
      : b[scope].status.localeCompare(a[scope].status);
  });

  // Sort non-favourites
  const sortedNonFavourites = [...nonFavourites].sort((a, b) => {
    if (scope === 'domain') {
      return scopeSort === 'asc'
        ? a.domain.localeCompare(b.domain)
        : b.domain.localeCompare(a.domain);
    }
    return scopeSort === 'asc'
      ? a[scope].status.localeCompare(b[scope].status)
      : b[scope].status.localeCompare(a[scope].status);
  });

  // Merge favourites and non-favourites
  return [...sortedFavourites, ...sortedNonFavourites];
};

/**
 * Sorts an array of sites by their favourite status.
 * Sites that are marked as favourites will appear before those that are not.
 *
 * @param {Array} sites - An array of site objects to be sorted.
 * @param {boolean} sites.isFavourite - The favourite status of the site. Sites with `true` will be prioritized.
 *
 * @returns {Array} A new array with sites sorted by their favourite status, with favourites appearing first.
 */
export const sortSitesByFavourites = (sites) => {
  if (sites)
    return [...sites].sort((a, b) => {
      if (a.isFavourite && !b.isFavourite) {
        return -1;
      }
      if (!a.isFavourite && b.isFavourite) {
        return 1;
      }
      return 0;
    });
  return [];
};
