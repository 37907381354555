import { useEffect, useState } from 'react';

/**
 * Returns the URL query parameters as an object
 * @returns {Object} The URL query parameters object
 */
export const queryParamsObject = () => {
  if (!window?.location?.search) return {};
  return Object.fromEntries(
    new URLSearchParams(window.location.search).entries(),
  );
};

/**
 * Custom hook to handle URLSearchParams
 *
 * @returns {Object} The query object and push function
 * @returns {Object} query - The URL query values object
 * @returns {Function} push - The push function to
 */
export const useURLSearchParams = () => {
  const [query, setQuery] = useState(queryParamsObject());

  /**
   * Updates the query state with the query params
   * The setTimeout is used to ensure the query state is updated after the document state is updated
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
   *
   * @returns {void}
   */
  const handleChange = () => {
    setTimeout(() => {
      setQuery(queryParamsObject());
    }, 0);
  };

  /**
   * Adds a new query param value entry to the browser's session history
   *
   * @param {String} key - The key to push
   * @param {String} value - The value to push
   * @returns {void}
   */
  const push = (key, value) => {
    if (typeof key !== 'string' || typeof value !== 'string') return;

    const url = new URL(window.location);
    url.searchParams.set(key, value);

    window.history.pushState({}, '', url);
    handleChange();
  };

  useEffect(() => {
    window.addEventListener('popstate', handleChange);
    return () => window.removeEventListener('popstate', handleChange);
  }, []);

  return {
    query,
    push,
  };
};
