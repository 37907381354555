import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { usePlanContext } from '../../wss-context/src';
import SecurityPlans from '../components';
import { SecurityPlansError } from '../lib';

/**
 * Security Plans Controller for rendering purchased plans
 * @param {Array} props.plans Array of active plans
 * @returns {JSX.Element} Security Plans
 */
const SecurityPlansController = ({ plans = [] }) => {
  let planData;

  try {
    // Determine if the context is available before rendering the controller.
    planData = usePlanContext(plans);
  } catch (error) {
    return (
      <div className="container d-flex justify-content-center py-4">
        <SecurityPlansError />
      </div>
    );
  }
  const [planList, setPlans] = useState(planData?.data || []);
  // Hydrate on client if sites not passed from server.
  useEffect(() => {
    setPlans(planData.data);
  }, [planData.data]);

  return (
    <>
      {!planData.isError && (
        <SecurityPlans planData={planData} planList={planList} />
      )}
      {planData.isError && (
        <SecurityPlansError recheckHandler={() => planData.refetch()} />
      )}
    </>
  );
};

SecurityPlansController.propTypes = {
  plans: PropTypes.array,
};

export default SecurityPlansController;
